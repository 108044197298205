@font-face {
    font-family: "Avenir";
    src: url("AvenirLTStd-Black.otf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Avenir";
    src: url("AvenirLTStd-Book.otf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Avenir";
    src: url("AvenirLTStd-Roman.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

